import CameraOutdoorIcon from '@mui/icons-material/CameraOutdoor';
import CastIcon from '@mui/icons-material/Cast';
import ComputerIcon from '@mui/icons-material/Computer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import NetworkWifiIcon from '@mui/icons-material/NetworkWifi';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import SearchIcon from '@mui/icons-material/Search';
import SettingsSystemDaydreamIcon from '@mui/icons-material/SettingsSystemDaydream';
import TvIcon from '@mui/icons-material/Tv';
import { Box, Button, Card, LinearProgress, SelectChangeEvent, Typography } from "@mui/material";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import Grid from '@mui/material/Grid';
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from '@mui/material/Select';
import { Theme, styled, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { Bar, BarChart, CartesianGrid, Cell, Pie, PieChart, Tooltip, XAxis, YAxis } from 'recharts';
import { getCallStats } from "../../../api/dashboard/support/callStatsApi";
import { getTicketSummary } from "../../../api/dashboard/support/ticketApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { UserProperty } from "../../../models/configuration/security/User";
import { CallStats as CallStatsModel } from "../../../models/dashboard/support/CallStats";
import { ServiceTicket } from "../../../models/dashboard/support/ServiceTicket";
import { PropertyTickets, TicketSummary as TicketSummaryModel, TicketsByDayOfWeek, TicketsByTimeOfDay, TicketsByType } from "../../../models/dashboard/support/TicketSummary";
import { useUser } from "../../../providers/UserProvider";
import themePrimary from "../../../styles/themePrimary";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import DataGrid from "../../common/datatable/DataGridWrapper";
import DateSearchField, { getDefaultEndDate } from "../../common/search/DateSearchField";
import TicketNoteDialog from "./TicketNoteDialog";
import UserPropertyDropdown from '../../common/UserPropertyDropdown';
import useFeatures from '../../../hooks/useFeatures';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { listFeatures } from '../../../api/accounts/featureApi';

const useStyles = makeStyles((theme: Theme) => ({
    chart: {
        margin: 'auto',
    },
    chartByPropertyGrid: {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        columnGap: themePrimary.spacing(2),
    },
    chartCard: {
        borderWidth: '2px !important',
        width: '500px',
        height: '372px',
    },
    chartCardLarge: {
        borderWidth: '2px !important',
        width: '1170px',
        height: '372px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    chartCardPie: {
        borderWidth: '2px !important',
        width: '500px',
        height: '372px',
        fontWeight: 'bold !important',
    },
    chartsContainer: {
        display: 'flex',
    },
    chartsContainerBottom: {
        display: 'flex',
        marginBottom: themePrimary.spacing(2),
    },
    chartGrid: {
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingBottom: themePrimary.spacing(2),
        display: 'grid',
        gridTemplateColumns: '33% 33% 33%',
        columnGap: themePrimary.spacing(2),
    },
    chartBarHeader: {
        textAlign: 'center',
        marginTop: `${themePrimary.spacing(1)} !important`,
        marginBottom: `${themePrimary.spacing(1)} !important`,
    },
    chartPieHeader: {
        textAlign: 'center',
        marginTop: '10px !important',
        marginBottom: '-20px !important',
    },
    root: {
       // padding: themePrimary.spacing(2),
    },
    icon: {
        height: '1.2em !important',
        width: '1.2em !important',
    },
    searchBox: {
        display: 'flex',
        paddingBottom: themePrimary.spacing(2),
        width: '100%',
    },   
    summaryGridCalls: {
        display: 'grid',
        gridTemplateColumns: '25% 25% 25% 25%',
    },    
    summaryGridACallsTop: {
        display: 'grid',
        gridTemplateColumns: '33% 33% 34%',
        marginBottom: themePrimary.spacing(2),
    },
    summaryBox: {
        //marginRight: themePrimary.spacing(2),
    },
    summaryBoxAlternate: {
        //marginRight: themePrimary.spacing(2),
        //marginBottom: themePrimary.spacing(2),
    },
    summaryBoxAlternateLast: {
        marginBottom: themePrimary.spacing(2),
    },
    summaryCardHeader: {

    },
    summaryCardGrid: {
        display: 'grid',
        gridTemplateColumns: '20% 80%',
    },
    summaryCard: {
        height: '100%',
        display: 'flex',
        padding: themePrimary.spacing(2),
        backgroundColor: themePrimary.palette.primary.main + " !important",
    },
    summaryCardTextContainer: {
        margin: 'auto',
    },
    summaryCardAlternate: {
        padding: themePrimary.spacing(2),
        borderWidth: '2px !important',
        backgroundColor: themePrimary.palette.grey[200] + " !important",
    },
    text: {
        color: themePrimary.palette.primary.contrastText,
        textAlign: 'center',
    },
    textAlternateHeader: {
        marginLeft: '16px !important',
    },
    textAlternate: {
        textAlign: 'left',
    },
    textAlternateLabel: {
        textAlign: 'left',
        marginRight: '4px',
    },
    detailsGrid: {
        display: 'grid',
        gridTemplateColumns: '70% 30%',
        textAlign: 'center',
    },
}));

const getDefaultSearchStartDate = (): Date => {
    var date = new Date();
    date.setMonth(0, 1);

    return date;
}

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:before': {
      display: 'none',
    },
}));
  
const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      {...props}
    />
  ))(({ theme }) => ({
    color: '#ffffff',
    backgroundColor: theme.palette.grey[500],
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
}));
  
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const TicketSummary = () => {

    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const { user } = useUser();
    const [isLoading, setIsLoading] = useState(true);
    const [notesDialogOpen, setNotesDialogOpen] = useState(false);    
    const [properties, setProperties] = useState<string[]>(user.currentProperty ? [user.currentProperty!.code] : []);   
    const [features, setFeatures] = useState<string[]>([]);
    const [selectedTicket, setSelectedTicket] = useState<ServiceTicket | undefined>(undefined);
    const [summary, setSummary] = useState<TicketSummaryModel | null>(null);
    const [callStats, setCallStats] = useState<CallStatsModel | null>(null);
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const [startDate, setStartDate] = useState<Date>(getDefaultSearchStartDate());
    const [endDate, setEndDate] = useState<Date>(getDefaultEndDate);
    const [ticketsByTypeChartData, setTicketsByTypeChartData] = useState<{name: string, value: number}[] | undefined>(undefined);
    const [ticketsByDayChartData, setTicketsByDayChartData] = useState<{name: string, value: number}[] | undefined>(undefined);
    const [ticketsByTimeOfDayChartData, setTicketsByTimeOfDayChartData] = useState<{name: string, value: number}[] | undefined>(undefined);
    const [ticketsByPropertyChartData, setTicketsByPropertyChartData] = useState<{name: string, value: number}[] | undefined>(undefined);
    const [callsAccordionExpanded, setCallsAccordionExpanded] = useState<boolean>(true);
    const [ticketsAccordionExpanded, setTicketsAccordionExpanded] = useState<boolean>(true);
    const classes = useStyles(useTheme());  
    const strings = useLocalizedStrings();
    const theme = useTheme();
    const axiosInstance = useCreateAxios();        

    const CHART_COLORS = ['#d50000', '#dd00dd', '#aa00ff', '#304ffe', '#0091ea', '#00bfa5', '#64dd17', '#00dddd' , '#ffd600', '#ff6d00', '#bbbbbb', '#858585', '#333333'];  
    
    async function apiGetTicketSummary(currentProperty?: string) {
        try {        
            let ids: string[];
            if (currentProperty) {
                ids = [currentProperty];
            }
            else {
                ids = properties;
            }

            if (ids.length === 0) {
                return;
            }

            let response = await getTicketSummary(axiosInstance, ids, startDate, endDate);                           

            if (response) {
                setSummary(response);   
                createTicketsByTypeChart(response.ticketsTotalByType);
                createTicketsByDayChart(response.ticketsByDayOfWeek);
                createTicketsByTimeOfDayChart(response.ticketsByTimeOfDay);
                createTicketsByPropertyChart(response.ticketsByProperty);
            }
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.errorRetrievingTickets.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });
        }
        finally {
            setIsLoading(false);
        }
    }

    async function apiGetCallStats() {
        try {                         
            const response = await getCallStats(axiosInstance, user.currentProperty!.code, startDate, endDate);            
            setCallStats(response);
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.errorRetrievingCallStats.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });
        }
        finally {
        }
    }

    useEffect(() => {        
        apiGetTicketSummary(user.currentProperty?.code);        
    }, [user.currentProperty?.code, strings.errorRetrievingTickets]);

    //useEffect(() => {        
    //    apiGetTicketSummary();        
    //}, [properties, strings.errorRetrievingTickets]);

    useEffect(() => {  
        //apiGetCallStats();      
        apiGetTicketSummary();                
    }, [strings.errorRetrievingTickets]);
    
    function getAllFeatures(propertyCodes?: string[]) {
        const allProperties = user.brands.flatMap(b => b.properties);
		   
        // Build a distinct list of features for selected properties
        var allFeatures: string[] = [];
        (propertyCodes ?? properties).forEach(p1 => {
            const foundProperty = allProperties.find(p2 => p2.id === p1);
            if (foundProperty && foundProperty.features) {
                foundProperty.features.forEach(f => {
                    if (!allFeatures.includes(f)) {
                        allFeatures.push(f);
                    }
                });
            }
        });

		return allFeatures;    
    }

    const createTicketsByTypeChart = (ticketsByType: TicketsByType) => {
        const pieChartData: {name: string, value: number}[] = [];

        pieChartData.push({name: strings.ticketSummaryNetwork, value: ticketsByType.network });
        pieChartData.push({name: strings.ticketSummaryVoice, value: ticketsByType.voice });
        pieChartData.push({name: strings.ticketSummaryTelevision, value: ticketsByType.television });
        pieChartData.push({name: strings.ticketSummarySecurity, value: ticketsByType.security });
        pieChartData.push({name: strings.ticketSummaryBackOfHouse, value: ticketsByType.backOfHouse });
        pieChartData.push({name: strings.ticketSummaryCasting, value: ticketsByType.casting });
        pieChartData.push({name: strings.ticketSummaryITV, value: ticketsByType.itv });
        pieChartData.push({name: strings.ticketSummaryMobileApp, value: ticketsByType.mobileApp });        
        pieChartData.push({name: strings.ticketSummaryDigitalSignage, value: ticketsByType.digitalSignage }); 
        pieChartData.push({name: strings.ticketSummaryDigiManager, value: ticketsByType.digiManager });
        pieChartData.push({name: strings.ticketSummaryAlerts, value: ticketsByType.alerts });
        pieChartData.push({name: strings.ticketSummaryAudio, value: ticketsByType.audio });
        pieChartData.push({name: strings.ticketSummaryOther, value: ticketsByType.other });      
        
        setTicketsByTypeChartData(pieChartData);
    }

    const createTicketsByDayChart = (ticketsByDay: TicketsByDayOfWeek) => {
        const barChartData: {name: string, value: number}[] = [];

        barChartData.push({name: strings.su, value: ticketsByDay[0]})
        barChartData.push({name: strings.m, value: ticketsByDay[1]})
        barChartData.push({name: strings.tu, value: ticketsByDay[2]})
        barChartData.push({name: strings.w, value: ticketsByDay[3]})
        barChartData.push({name: strings.th, value: ticketsByDay[4]})
        barChartData.push({name: strings.f, value: ticketsByDay[5]})
        barChartData.push({name: strings.sa, value: ticketsByDay[6]})

        setTicketsByDayChartData(barChartData);
    }

    const createTicketsByTimeOfDayChart = (ticketsByTimeOfDay: TicketsByTimeOfDay) => {
        const barChartData: {name: string, value: number}[] = [];

        barChartData.push({name: "12A", value: ticketsByTimeOfDay[0]})
        barChartData.push({name: "4A", value: ticketsByTimeOfDay[1]})
        barChartData.push({name: "8A", value: ticketsByTimeOfDay[2]})
        barChartData.push({name: "12P", value: ticketsByTimeOfDay[3]})
        barChartData.push({name: "4P", value: ticketsByTimeOfDay[4]})
        barChartData.push({name: "8P", value: ticketsByTimeOfDay[5]})

        setTicketsByTimeOfDayChartData(barChartData);
    }

    const createTicketsByPropertyChart = (ticketsByProperty: PropertyTickets[]) => {
        const barChartData: {name: string, value: number}[] = [];

        ticketsByProperty.forEach( p =>
            barChartData.push({name: p.propertyName, value: p.ticketCount })
        ) 

        setTicketsByPropertyChartData(barChartData);
    }

    function handlePropertyChange(propertyCodes: string[]) {
        setProperties(propertyCodes);
        setFeatures(getAllFeatures(propertyCodes));
    }

    const onSearchClickHandler = () => {
        setIsLoading(true);
        apiGetTicketSummary();
    }

    const onDateSearchFieldChangeHandler = (startDate: Date, endDate: Date) => {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    const handleAccordionChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            if (panel === "callsAccordion") {
                setCallsAccordionExpanded(isExpanded);
            }
            else if (panel === "ticketsAccordion") {
                setTicketsAccordionExpanded(isExpanded);
            }
        };

    const gridColumns : GridColDef[] = [        
        { field: "number", headerName: strings.ticketGridIdHeader, flex: 1, valueGetter: (value, row) => row.number},
        { field: "companyName", headerName: strings.ticketGridPropertyNameHeader, flex: 2},
        { field: "summary", headerName: strings.ticketGridSummaryHeader, flex: 3},
        { field: "openDateTime", headerName: strings.ticketGridOpenDateTimeHeader, valueGetter: (value, row) => new Date(row.openDateTime).toLocaleDateString(), flex: 1},
        { field: "status", headerName: strings.ticketGridStatusHeader, flex: 1},
        {
          field: "actions",
          type: "actions",
          headerName: strings.gridActions,
          flex: 1,
          getActions: (params: GridRowParams) => [
            <GridActionsCellItem
              icon={<SearchIcon titleAccess={strings.gridActionViewTitleAccess} sx={{ color: "primary.dark" }} />}
              onClick={() => handleViewRowClick(params.id.toString())}
              label="View"
            />,
          ],
        },
    ];

    const handleViewRowClick = async (id: string) => {        
        var ticket: ServiceTicket | undefined = summary?.recentTickets.find(t => t.id === id);

        if (ticket !== undefined) {
            setSelectedTicket(ticket);
            setNotesDialogOpen(true);
        }
    }        

    return (
        <Box sx={{ padding: theme.spacing(2) }}> 
            <div className={classes.root}>
                <div className={classes.searchBox}>                    
                    <UserPropertyDropdown onChange={handlePropertyChange} />
                    <DateSearchField 
                        startDate={getDefaultSearchStartDate()}
                        endDate={null}
                        format={null}
                        onChange={onDateSearchFieldChangeHandler} />
                    <Button
                        sx={{ marginLeft: themePrimary.spacing(2) }}
                        variant="contained"
                        size="large"
                        disabled={false}
                        onClick={onSearchClickHandler}
                        startIcon={<SearchIcon />}
                        aria-label="Search">
                        {strings.search}
                    </Button>
                </div>
                    
                {isLoading &&
                    <LinearProgress />
                }

                <div>
                    {callStats !== null &&
                        <div>
                            <Accordion expanded={callsAccordionExpanded} onChange={handleAccordionChange("callsAccordion")}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }}/>}
                                    aria-controls="accordion-calls-summary"
                                    id="accordion-calls-summary"
                                >
                                    <Typography>{strings.callSummaryAccordion}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {!callStats.isSharedQueue &&
                                        <div className={classes.summaryGridACallsTop}>
                                            <Box className={classes.summaryBox}>
                                                <Card variant="outlined" className={classes.summaryCard}>
                                                    <div className={classes.summaryCardTextContainer}>
                                                        <Typography variant="h5" component="div" className={classes.text}>
                                                            {strings.callSummaryTotalCalls}
                                                        </Typography>
                                                        <Typography variant="h4" component="div" className={classes.text}>
                                                            {callStats.callsOffered}
                                                        </Typography>
                                                    </div>
                                                </Card>
                                            </Box>

                                            <Box className={classes.summaryBox}>
                                                <Card variant="outlined" className={classes.summaryCard}>
                                                    <div className={classes.summaryCardTextContainer}>
                                                        <Typography variant="h5" component="div" className={classes.text}>
                                                            {strings.callSummaryTotalCallsHandled}
                                                        </Typography>
                                                        <Typography variant="h4" component="div" className={classes.text}>
                                                            {callStats.callsHandled}
                                                        </Typography>
                                                    </div>
                                                </Card>
                                            </Box>

                                            <Box className={classes.summaryBox}>
                                                <Card variant="outlined" className={classes.summaryCard}>
                                                    <div className={classes.summaryCardTextContainer}>
                                                        <Typography variant="h5" component="div" className={classes.text}>
                                                            {strings.callSummaryTotalCallsAbandoned}
                                                        </Typography>
                                                        <Typography variant="h4" component="div" className={classes.text}>
                                                            {callStats.callsAbandoned}
                                                        </Typography>
                                                    </div>
                                                </Card>
                                            </Box>
                                        </div>
                                    }
                                    
                                    <div className={classes.summaryGridCalls}>
                                        <Box className={classes.summaryBox}>
                                            <Card variant="outlined" className={classes.summaryCardAlternate}>
                                                <div>
                                                    <div className={classes.detailsGrid}>
                                                        <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                            {strings.callSummaryAvgSpeedAnswer}
                                                        </Typography>
                                                        <Typography variant="body1" component="div" style={{textAlign: 'right', marginRight: '10dp'}} className={classes.textAlternate}>
                                                            {callStats.avgSpeedAnswer}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Box>
                                        <Box className={classes.summaryBox}>
                                            <Card variant="outlined" className={classes.summaryCardAlternate}>
                                                <div>
                                                    <div className={classes.detailsGrid}>
                                                        <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                            {strings.callSummaryAvgHandlingTime}
                                                        </Typography>
                                                        <Typography variant="body1" component="div" style={{textAlign: 'right', marginRight: '10dp'}} className={classes.textAlternate}>
                                                            {callStats.avgHandlingTime}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Box>
                                        <Box className={classes.summaryBox}>
                                            <Card variant="outlined" className={classes.summaryCardAlternate}>
                                                <div>
                                                    <div className={classes.detailsGrid}>
                                                        <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                            {strings.callSummaryAnswerPercent}
                                                        </Typography>
                                                        <Typography variant="body1" component="div" style={{textAlign: 'right', marginRight: '10dp'}} className={classes.textAlternate}>
                                                            {callStats.answerPercent}%
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Box>
                                        <Box className={classes.summaryBox}>
                                            <Card variant="outlined" className={classes.summaryCardAlternate}>
                                                <div>
                                                    <div className={classes.detailsGrid}>
                                                        <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                            {strings.callSummaryAbandonPercent}
                                                        </Typography>
                                                        <Typography variant="body1" component="div" style={{textAlign: 'right', marginRight: '10dp'}} className={classes.textAlternate}>
                                                            {callStats.abandonPercent}%
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Box>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Spacer/>
                            <Spacer/>
                            <Spacer/>
                        </div>
                    }
                    
                    {callStats !== null &&
                        <Accordion expanded={ticketsAccordionExpanded} onChange={handleAccordionChange("ticketsAccordion")}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
                                aria-controls="accordion-tickets-summary"
                                id="accordion-tickets-summary"
                            >
                                <Typography>{strings.ticketSummaryAccordion}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            {summary !== null && !isLoading &&
                            <div>
                                <Grid item container spacing={2}>
                                    <Grid item xs={4}>
                                        <Card variant="outlined" className={classes.summaryCard}>
                                            <div className={classes.summaryCardTextContainer}>
                                                <Typography variant="h5" component="div" className={classes.text}>
                                                    {strings.ticketSummaryTotalOpenTickets}
                                                </Typography>
                                                <Typography variant="h4" component="div" className={classes.text}>
                                                    {summary.totalOpenTickets.toString()}
                                                </Typography>
                                            </div>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Card variant="outlined" className={classes.summaryCard}>
                                            <div className={classes.summaryCardTextContainer}>
                                                <Typography variant="h5" component="div" className={classes.text}>
                                                    {strings.ticketSummaryTotalClosedTickets}
                                                </Typography>
                                                <Typography variant="h4" component="div" className={classes.text}>
                                                    {summary.totalClosedTickets.toString()}
                                                </Typography>
                                            </div>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Card variant="outlined" className={classes.summaryCard}>
                                            <div className={classes.summaryCardTextContainer}>
                                                <Typography variant="h5" component="div" className={classes.text}>
                                                    {strings.ticketSummaryAverageOpenDuration}
                                                </Typography>
                                                <Typography variant="h4" component="div" className={classes.text}>
                                                    {summary.averageOpenDuration.toString()}&nbsp;{strings.dayDays}
                                                </Typography>
                                            </div>
                                        </Card>
                                    </Grid>
                                </Grid>   
                                <Grid container spacing={2} sx={{ marginBottom: theme.spacing(2) }}>
                                    {(features.includes("NETWORK") || features.includes("NETWORK_CIRCUITONLY") || features.includes("CIRCUIT") || features.includes("POTS") || features.includes("WIFI")) && 
                                        <Grid item xs={2}>                                        
                                            <Card variant="outlined" className={classes.summaryCardAlternate}>
                                                <div className={classes.summaryCardGrid}>
                                                    <NetworkWifiIcon className={classes.icon} />
                                                    <div>
                                                        <div className={classes.detailsGrid}>
                                                            <Typography variant="h6" component="div" className={classes.textAlternateLabel}>
                                                                {strings.ticketSummaryNetwork}
                                                            </Typography>
                                                        </div>
                                        
                                                        <div className={classes.detailsGrid}>
                                                            <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                                {strings.ticketSummaryTotalOpen}
                                                            </Typography>
                                                            <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                                {summary.ticketsOpenByType.network!!}
                                                            </Typography>
                                                        </div>

                                                        <div className={classes.detailsGrid}>
                                                            <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                                {strings.ticketSummaryTotalClosed}
                                                            </Typography>
                                            
                                                            <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                                {summary.ticketsClosedByType.network!!}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div>                                        
                                            </Card>                                        
                                        </Grid>
                                    }
                                    {features.includes("VOICE") && 
                                        <Grid item xs={2}>                                        
                                            <Card variant="outlined" className={classes.summaryCardAlternate}>
                                                <div className={classes.summaryCardGrid}>
                                                    <PhoneIcon className={classes.icon} />
                                                    <div>
                                                        <Typography variant="h6" component="div" className={classes.textAlternateLabel}>
                                                            {strings.ticketSummaryVoice}
                                                        </Typography>

                                                        <div className={classes.detailsGrid}>
                                                            <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                                {strings.ticketSummaryTotalOpen}
                                                            </Typography>
                                                            <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                                {summary.ticketsOpenByType.voice!}
                                                            </Typography>
                                                        </div>

                                                        <div className={classes.detailsGrid}>
                                                            <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                                {strings.ticketSummaryTotalClosed}
                                                            </Typography>
                                                            <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                                {summary.ticketsClosedByType.voice!!}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>                                        
                                        </Grid>
                                    }
                                    {features.includes("TV") && 
                                        <Grid item xs={2}>                                        
                                            <Card variant="outlined" className={classes.summaryCardAlternate}>
                                                <div className={classes.summaryCardGrid}>
                                                    <TvIcon className={classes.icon} />
                                                    <div>
                                                        <Typography variant="h6" component="div" className={classes.textAlternateLabel}>
                                                            {strings.ticketSummaryTelevision}
                                                        </Typography>

                                                        <div className={classes.detailsGrid}>
                                                            <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                                {strings.ticketSummaryTotalOpen}
                                                            </Typography>
                                                            <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                                {summary.ticketsOpenByType.television!!}
                                                            </Typography>
                                                        </div>

                                                        <div className={classes.detailsGrid}>
                                                            <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                                {strings.ticketSummaryTotalClosed}
                                                            </Typography>
                                                            <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                                {summary.ticketsClosedByType.television!!}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>                                        
                                        </Grid>
                                    }
                                    {features.includes("SECURITY") && 
                                        <Grid item xs={2}>                                        
                                            <Card variant="outlined" className={classes.summaryCardAlternate}>
                                                <div className={classes.summaryCardGrid}>
                                                    <CameraOutdoorIcon className={classes.icon} />
                                                    <div>
                                                        <Typography variant="h6" component="div" className={classes.textAlternateLabel}>
                                                            {strings.ticketSummarySecurity}
                                                        </Typography>

                                                        <div className={classes.detailsGrid}>
                                                            <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                                {strings.ticketSummaryTotalOpen}
                                                            </Typography>
                                                            <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                                {summary.ticketsOpenByType.security!!}
                                                            </Typography>
                                                        </div>

                                                        <div className={classes.detailsGrid}>
                                                            <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                                {strings.ticketSummaryTotalClosed}
                                                            </Typography>
                                                            <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                                {summary.ticketsClosedByType.security!}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Grid>                                  
                                    }
                                    {features.includes("CASTING") && 
                                        <Grid item xs={2}>                                        
                                            <Card variant="outlined" className={classes.summaryCardAlternate}>
                                                <div className={classes.summaryCardGrid}>
                                                    <CastIcon className={classes.icon} />
                                                    <div>
                                                        <Typography variant="h6" component="div" className={classes.textAlternateLabel}>
                                                            {strings.ticketSummaryCasting}
                                                        </Typography>
                                                        <div className={classes.detailsGrid}>
                                                            <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                                {strings.ticketSummaryTotalOpen}
                                                            </Typography>
                                                            <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                                {summary.ticketsOpenByType.casting!!}
                                                            </Typography>
                                                        </div>

                                                        <div className={classes.detailsGrid}>
                                                            <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                                {strings.ticketSummaryTotalClosed}
                                                            </Typography>
                                                            <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                                {summary.ticketsClosedByType.casting!!}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>                                        
                                        </Grid>
                                    }
                                    {features.includes("ITV") && 
                                        <Grid item xs={2}>                                        
                                            <Card variant="outlined" className={classes.summaryCardAlternate}>
                                                <div className={classes.summaryCardGrid}>
                                                    <LiveTvIcon className={classes.icon} />
                                                    <div>
                                                        <Typography variant="h6" component="div" className={classes.textAlternateLabel}>
                                                            {strings.ticketSummaryITV}
                                                        </Typography>
                                                        <div className={classes.detailsGrid}>
                                                            <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                                {strings.ticketSummaryTotalOpen}
                                                            </Typography>
                                                            <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                                {summary.ticketsOpenByType.itv!!}
                                                            </Typography>
                                                        </div>

                                                        <div className={classes.detailsGrid}>
                                                            <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                                {strings.ticketSummaryTotalClosed}
                                                            </Typography>
                                                            <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                                {summary.ticketsClosedByType.itv!!}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Grid>
                                    }
                                    {features.includes("MOBILE") && 
                                        <Grid item xs={2}>                                        
                                            <Card variant="outlined" className={classes.summaryCardAlternate}>
                                                <div className={classes.summaryCardGrid}>
                                                    <PhoneIphoneIcon className={classes.icon} />
                                                    <div>
                                                        <Typography variant="h6" component="div" className={classes.textAlternateLabel}>
                                                            {strings.ticketSummaryMobileApp}
                                                        </Typography>
                                                        <div className={classes.detailsGrid}>
                                                            <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                                {strings.ticketSummaryTotalOpen}
                                                            </Typography>
                                                            <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                                {summary.ticketsOpenByType.mobileApp!!}
                                                            </Typography>
                                                        </div>

                                                        <div className={classes.detailsGrid}>
                                                            <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                                {strings.ticketSummaryTotalClosed}
                                                            </Typography>
                                                            <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                                {summary.ticketsClosedByType.mobileApp!!}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>                                        
                                        </Grid>
                                    }
                                    {features.includes("SIGN") && 
                                        <Grid item xs={2}>                                        
                                            <Card variant="outlined" className={classes.summaryCardAlternate}>
                                                <div className={classes.summaryCardGrid}>
                                                    <SettingsSystemDaydreamIcon className={classes.icon} />
                                                    <div>
                                                        <Typography variant="h6" component="div" className={classes.textAlternateLabel}>
                                                            {strings.ticketSummaryDigitalSignage}
                                                        </Typography>
                                                        <div className={classes.detailsGrid}>
                                                            <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                                {strings.ticketSummaryTotalOpen}
                                                            </Typography>
                                                            <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                                {summary.ticketsOpenByType.digitalSignage!!}
                                                            </Typography>
                                                        </div>

                                                        <div className={classes.detailsGrid}>
                                                            <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                                {strings.ticketSummaryTotalClosed}
                                                            </Typography>
                                                            <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                                {summary.ticketsClosedByType.digitalSignage!!}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Grid>
                                    }
                                    <Grid item xs={2}>
                                        <Card variant="outlined" className={classes.summaryCardAlternate}>
                                            <div className={classes.summaryCardGrid}>
                                                <ComputerIcon className={classes.icon} />
                                                <div>
                                                    <Typography variant="h6" component="div" className={classes.textAlternateLabel}>
                                                        {strings.ticketSummaryDigiManager}
                                                    </Typography>
                                                    <div className={classes.detailsGrid}>
                                                        <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                            {strings.ticketSummaryTotalOpen}
                                                        </Typography>
                                                        <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                            {summary.ticketsOpenByType.digiManager!!}
                                                        </Typography>
                                                    </div>

                                                    <div className={classes.detailsGrid}>
                                                        <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                            {strings.ticketSummaryTotalClosed}
                                                        </Typography>
                                                        <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                            {summary.ticketsClosedByType.digiManager!!}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Grid>  
                                    {features.includes("ALERT") && 
                                        <Grid item xs={2}>                                        
                                            <Card variant="outlined" className={classes.summaryCardAlternate}>
                                                <div className={classes.summaryCardGrid}>
                                                    <NotificationImportantIcon className={classes.icon} />
                                                    <div>
                                                        <Typography variant="h6" component="div" className={classes.textAlternateLabel}>
                                                            {strings.ticketSummaryAlerts}
                                                        </Typography>
                                                        <div className={classes.detailsGrid}>
                                                            <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                                {strings.ticketSummaryTotalOpen}
                                                            </Typography>
                                                            <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                                {summary.ticketsOpenByType.alerts!!}
                                                            </Typography>
                                                        </div>

                                                        <div className={classes.detailsGrid}>
                                                            <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                                {strings.ticketSummaryTotalClosed}
                                                            </Typography>
                                                            <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                                {summary.ticketsClosedByType.alerts!!}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>                                        
                                        </Grid>  
                                    }
                                    <Grid item xs={2}>
                                        <Card variant="outlined" className={classes.summaryCardAlternate}>
                                            <div className={classes.summaryCardGrid}>
                                                <AllInclusiveIcon className={classes.icon} />
                                                <div>
                                                    <Typography variant="h6" component="div" className={classes.textAlternateLabel}>
                                                        {strings.ticketSummaryOther}
                                                    </Typography>
                                                    <div className={classes.detailsGrid}>
                                                        <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                            {strings.ticketSummaryTotalOpen}
                                                        </Typography>
                                                        <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                            {summary.ticketsOpenByType.other!!}
                                                        </Typography>
                                                    </div>

                                                    <div className={classes.detailsGrid}>
                                                        <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                            {strings.ticketSummaryTotalClosed}
                                                        </Typography>
                                                        <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                            {summary.ticketsClosedByType.other!!}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Grid>  
                                </Grid>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <Grid container spacing={2}>
                                            {ticketsByTypeChartData !== undefined &&
                                                <Grid item>
                                                    <Card variant="outlined" className={classes.chartCard}>
                                                        <Typography variant="h6" component="div" className={classes.chartBarHeader}>
                                                            {strings.ticketsByType}
                                                        </Typography>                                            
                                                        <div className={classes.chartByPropertyGrid}>
                                                            <PieChart width={260} height={312} className={classes.chart}>
                                                                <Pie
                                                                    dataKey="value"
                                                                    data={ticketsByTypeChartData}
                                                                    cx="50%"
                                                                    cy="50%"
                                                                    outerRadius={100}
                                                                    fill="#8884d8">                                                            
                                                                        {ticketsByTypeChartData.map((entry, index) => (
                                                                            <Cell key={`cell-${index}`} fill={CHART_COLORS[index % CHART_COLORS.length]} />
                                                                        ))}
                                                                </Pie>
                                                                <Tooltip content={({ active, payload, label }) => {
                                                                    if (active && payload && payload.length) {
                                                                        return (
                                                                            <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', fontWeight: 'normal', padding: '10px', width: '100%', height: '100%'}}>
                                                                            <p className="label">{payload[0].payload.name}</p>
                                                                            <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                            </div>
                                                                        );
                                                                    }

                                                                    return null;
                                                                }}/>
                                                            </PieChart>
                                                            <div>
                                                                {ticketsByTypeChartData.map((entry, index) => (
                                                                    entry.value > 0 &&
                                                                    <div style={{display: 'flex'}}>
                                                                        <div style={{height: '15px', width: '15px', marginTop: 'auto', marginBottom: 'auto', marginRight: '10px', backgroundColor: CHART_COLORS[index % CHART_COLORS.length]}}></div>
                                                                        <span>{entry.name}</span>
                                                                    </div>
                                                            
                                                                ))}
                                                            </div>
                                                        </div>                                           
                                                    </Card>
                                                </Grid>
                                            }

                                            {ticketsByDayChartData !== undefined &&
                                                <Grid item>
                                                    <Card variant="outlined" className={classes.chartCard}>
                                                        <Typography variant="h6" component="div" className={classes.chartBarHeader}>
                                                            {strings.ticketsByDay}
                                                        </Typography>
                                                        <BarChart
                                                            width={480}
                                                            height={312}
                                                            data={ticketsByDayChartData}>
                                                            <CartesianGrid strokeDasharray="3 3" />
                                                            <XAxis dataKey="name" />
                                                            <YAxis />
                                                            <Tooltip content={({ active, payload, label }) => {
                                                                if (active && payload && payload.length) {
                                                                    if (label === "SU") {
                                                                        return (
                                                                            <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                            <p className="label">{"Sunday"}</p>
                                                                            <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                            </div>
                                                                        );
                                                                    }
                                                                    else if (label === "M") {
                                                                        return (
                                                                            <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                            <p className="label">{"Monday"}</p>
                                                                            <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                            </div>
                                                                        );
                                                                    }
                                                                    else if (label === "TU") {
                                                                        return (
                                                                            <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                            <p className="label">{"Tuesday"}</p>
                                                                            <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                            </div>
                                                                        );
                                                                    }
                                                                    else if (label === "W") {
                                                                        return (
                                                                            <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                            <p className="label">{"Wednesday"}</p>
                                                                            <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                            </div>
                                                                        );
                                                                    }
                                                                    else if (label === "TH") {
                                                                        return (
                                                                            <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                            <p className="label">{"Thursday"}</p>
                                                                            <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                            </div>
                                                                        );
                                                                    }
                                                                    else if (label === "F") {
                                                                        return (
                                                                            <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                            <p className="label">{"Friday"}</p>
                                                                            <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                            </div>
                                                                        );
                                                                    }
                                                                    else if (label === "SA") {
                                                                        return (
                                                                            <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                            <p className="label">{"Saturday"}</p>
                                                                            <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                            </div>
                                                                        );
                                                                    }
                                                                }
                                                        
                                                                return null;
                                                            }}/>
                                                            <Bar dataKey="value">
                                                                {ticketsByDayChartData.map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={CHART_COLORS[index % CHART_COLORS.length]} />
                                                                ))}
                                                            </Bar>
                                                        </BarChart>
                                                    </Card>
                                                </Grid>
                                            }

                                            {ticketsByTimeOfDayChartData !== undefined &&
                                                <Grid item>
                                                    <Card variant="outlined" className={classes.chartCard}>
                                                        <Typography variant="h6" component="div" className={classes.chartBarHeader}>
                                                            {strings.ticketsByTime}
                                                        </Typography>
                                                        <BarChart
                                                            width={480}
                                                            height={312}
                                                            data={ticketsByTimeOfDayChartData}>
                                                            <CartesianGrid strokeDasharray="3 3" />
                                                            <XAxis dataKey="name" />
                                                            <YAxis />
                                                            <Tooltip content={({ active, payload, label }) => {
                                                                if (active && payload && payload.length) {
                                                                    if (label === "12A") {
                                                                        return (
                                                                            <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                            <p className="label">{"12AM"}</p>
                                                                            <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                            </div>
                                                                        );
                                                                    }
                                                                    else if (label === "4A") {
                                                                        return (
                                                                            <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                            <p className="label">{"4AM"}</p>
                                                                            <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                            </div>
                                                                        );
                                                                    }
                                                                    else if (label === "8A") {
                                                                        return (
                                                                            <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                            <p className="label">{"8AM"}</p>
                                                                            <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                            </div>
                                                                        );
                                                                    }
                                                                    else if (label === "12P") {
                                                                        return (
                                                                            <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                            <p className="label">{"12PM"}</p>
                                                                            <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                            </div>
                                                                        );
                                                                    }
                                                                    else if (label === "4P") {
                                                                        return (
                                                                            <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                            <p className="label">{"4PM"}</p>
                                                                            <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                            </div>
                                                                        );
                                                                    }
                                                                    else if (label === "8P") {
                                                                        return (
                                                                            <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                            <p className="label">{"8PM"}</p>
                                                                            <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                            </div>
                                                                        );
                                                                    }
                                                                }
                                                        
                                                                return null;
                                                            }}/>                                        
                                                            <Bar dataKey="value">
                                                                {ticketsByTimeOfDayChartData.map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={CHART_COLORS[index % CHART_COLORS.length]} />
                                                                ))}
                                                            </Bar>
                                                        </BarChart>
                                                    </Card>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>                        
                                    {properties.length > 1 &&
                                        <Grid item>
                                            <div className={classes.chartsContainerBottom}>
                                            {ticketsByPropertyChartData !== undefined &&
                                                <Card variant="outlined" className={classes.chartCardLarge}>
                                                    <Typography variant="h6" component="div" className={classes.chartBarHeader}>
                                                        {strings.ticketsByProperty}
                                                    </Typography>
                                                    <div className={classes.chartByPropertyGrid}>
                                                        <BarChart
                                                            width={520}
                                                            height={304}
                                                            data={ticketsByPropertyChartData}>
                                                            <CartesianGrid strokeDasharray="3 3" />
                                                            <YAxis />
                                                            <Tooltip content={({ active, payload, label }) => {
                                                                if (active && payload && payload.length) {
                                                                    return (
                                                                        <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                        <p className="label">{payload[0].payload.name}</p>
                                                                        <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                        </div>
                                                                    );
                                                                }

                                                                return null;
                                                            }}/>
                                                            <Bar dataKey="value" isAnimationActive={true}>
                                                                {ticketsByPropertyChartData.map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={CHART_COLORS[index % CHART_COLORS.length]} />
                                                                ))}
                                                            </Bar>
                                                        </BarChart>
                                                        <div>
                                                            {ticketsByPropertyChartData.map((entry, index) => (
                                                                <div style={{display: 'flex'}}>
                                                                    <div style={{height: '15px', width: '15px', marginTop: 'auto', marginBottom: 'auto', marginRight: '10px', backgroundColor: CHART_COLORS[index % CHART_COLORS.length]}}></div>
                                                                    <span>{entry.name}</span>
                                                                </div>
                                                            
                                                            ))}
                                                        </div>
                                                    </div>
                                                </Card>
                                            }
                                        </div>
                                        </Grid>
                                    }                        
                                    </Grid>
                                    <Spacer y={2} />
                                    <Typography variant="h6">
                                        {strings.recentTickets}
                                    </Typography>
                                    <Spacer y={1} />
                                    <DataGrid
                                        getRowId={(row) => row.id} 
                                        rows={summary.recentTickets}
                                        columns={gridColumns}
                                        autoHeight={true} />
                                </div>
                            }
                            </AccordionDetails>
                        </Accordion>
                    }

                    {summary !== null && !isLoading &&
                    <div>                        
                        <Grid container spacing={2} sx={{ marginBottom: theme.spacing(2) }}>
                            <Grid item xs={4}>                            
                                <Card variant="outlined" className={classes.summaryCard}>
                                    <div className={classes.summaryCardTextContainer}>
                                        <Typography variant="h5" component="div" className={classes.text}>
                                            {strings.ticketSummaryTotalOpenTickets}
                                        </Typography>
                                        <Typography variant="h4" component="div" className={classes.text}>
                                            {summary.totalOpenTickets.toString()}
                                        </Typography>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item xs={4}>
                                <Card variant="outlined" className={classes.summaryCard}>
                                    <div className={classes.summaryCardTextContainer}>
                                        <Typography variant="h5" component="div" className={classes.text}>
                                            {strings.ticketSummaryTotalClosedTickets}
                                        </Typography>
                                        <Typography variant="h4" component="div" className={classes.text}>
                                            {summary.totalClosedTickets.toString()}
                                        </Typography>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item xs={4}>
                                <Card variant="outlined" className={classes.summaryCard}>
                                    <div className={classes.summaryCardTextContainer}>
                                        <Typography variant="h5" component="div" className={classes.text}>
                                            {strings.ticketSummaryAverageOpenDuration}
                                        </Typography>
                                        <Typography variant="h4" component="div" className={classes.text}>
                                            {summary.averageOpenDuration.toString()}&nbsp;{strings.dayDays}
                                        </Typography>
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>    
                        <Grid container spacing={2} sx={{ marginBottom: theme.spacing(2) }}>
                            {(features.includes("NETWORK") || features.includes("NETWORK_CIRCUITONLY") || features.includes("CIRCUIT") || features.includes("POTS") || features.includes("WIFI")) && 
                                <Grid item xs={2}>                                
                                    <Card variant="outlined" className={classes.summaryCardAlternate}>
                                        <div className={classes.summaryCardGrid}>
                                            <NetworkWifiIcon className={classes.icon} />
                                            <div>
                                                <div className={classes.detailsGrid}>
                                                    <Typography variant="h6" component="div" className={classes.textAlternateLabel}>
                                                        {strings.ticketSummaryNetwork}
                                                    </Typography>
                                                </div>
                                        
                                                <div className={classes.detailsGrid}>
                                                    <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                        {strings.ticketSummaryTotalOpen}
                                                    </Typography>
                                                    <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                        {summary.ticketsOpenByType.network!!}
                                                    </Typography>
                                                </div>

                                                <div className={classes.detailsGrid}>
                                                    <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                        {strings.ticketSummaryTotalClosed}
                                                    </Typography>
                                            
                                                    <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                        {summary.ticketsClosedByType.network!!}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </Card>                                
                                </Grid>
                            }
                            {features.includes("VOICE") && 
                                <Grid item xs={2}>                                
                                    <Card variant="outlined" className={classes.summaryCardAlternate}>
                                        <div className={classes.summaryCardGrid}>
                                            <PhoneIcon className={classes.icon} />
                                            <div>
                                                <Typography variant="h6" component="div" className={classes.textAlternateLabel}>
                                                    {strings.ticketSummaryVoice}
                                                </Typography>

                                                <div className={classes.detailsGrid}>
                                                    <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                        {strings.ticketSummaryTotalOpen}
                                                    </Typography>
                                                    <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                        {summary.ticketsOpenByType.voice!}
                                                    </Typography>
                                                </div>

                                                <div className={classes.detailsGrid}>
                                                    <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                        {strings.ticketSummaryTotalClosed}
                                                    </Typography>
                                                    <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                        {summary.ticketsClosedByType.voice!!}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>                                
                                </Grid>
                            }
                            {features.includes("TV") && 
                                <Grid item xs={2}>                                
                                    <Card variant="outlined" className={classes.summaryCardAlternate}>
                                        <div className={classes.summaryCardGrid}>
                                            <TvIcon className={classes.icon} />
                                            <div>
                                                <Typography variant="h6" component="div" className={classes.textAlternateLabel}>
                                                    {strings.ticketSummaryTelevision}
                                                </Typography>

                                                <div className={classes.detailsGrid}>
                                                    <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                        {strings.ticketSummaryTotalOpen}
                                                    </Typography>
                                                    <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                        {summary.ticketsOpenByType.television!!}
                                                    </Typography>
                                                </div>

                                                <div className={classes.detailsGrid}>
                                                    <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                        {strings.ticketSummaryTotalClosed}
                                                    </Typography>
                                                    <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                        {summary.ticketsClosedByType.television!!}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Grid>
                            }
                            {features.includes("SECURITY") && 
                                <Grid item xs={2}>                                
                                    <Card variant="outlined" className={classes.summaryCardAlternate}>
                                        <div className={classes.summaryCardGrid}>
                                            <CameraOutdoorIcon className={classes.icon} />
                                            <div>
                                                <Typography variant="h6" component="div" className={classes.textAlternateLabel}>
                                                    {strings.ticketSummarySecurity}
                                                </Typography>

                                                <div className={classes.detailsGrid}>
                                                    <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                        {strings.ticketSummaryTotalOpen}
                                                    </Typography>
                                                    <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                        {summary.ticketsOpenByType.security!!}
                                                    </Typography>
                                                </div>

                                                <div className={classes.detailsGrid}>
                                                    <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                        {strings.ticketSummaryTotalClosed}
                                                    </Typography>
                                                    <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                        {summary.ticketsClosedByType.security!}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Grid>                        
                            }
                            {features.includes("CASTING") && 
                                <Grid item xs={2}>                                
                                    <Card variant="outlined" className={classes.summaryCardAlternate}>
                                        <div className={classes.summaryCardGrid}>
                                            <CastIcon className={classes.icon} />
                                            <div>
                                                <Typography variant="h6" component="div" className={classes.textAlternateLabel}>
                                                    {strings.ticketSummaryCasting}
                                                </Typography>
                                                <div className={classes.detailsGrid}>
                                                    <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                        {strings.ticketSummaryTotalOpen}
                                                    </Typography>
                                                    <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                        {summary.ticketsOpenByType.casting!!}
                                                    </Typography>
                                                </div>

                                                <div className={classes.detailsGrid}>
                                                    <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                        {strings.ticketSummaryTotalClosed}
                                                    </Typography>
                                                    <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                        {summary.ticketsClosedByType.casting!!}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Grid>
                            }
                            {features.includes("ITV") && 
                                <Grid item xs={2}>                                
                                    <Card variant="outlined" className={classes.summaryCardAlternate}>
                                        <div className={classes.summaryCardGrid}>
                                            <LiveTvIcon className={classes.icon} />
                                            <div>
                                                <Typography variant="h6" component="div" className={classes.textAlternateLabel}>
                                                    {strings.ticketSummaryITV}
                                                </Typography>
                                                <div className={classes.detailsGrid}>
                                                    <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                        {strings.ticketSummaryTotalOpen}
                                                    </Typography>
                                                    <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                        {summary.ticketsOpenByType.itv!!}
                                                    </Typography>
                                                </div>

                                                <div className={classes.detailsGrid}>
                                                    <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                        {strings.ticketSummaryTotalClosed}
                                                    </Typography>
                                                    <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                        {summary.ticketsClosedByType.itv!!}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>                                
                                </Grid>
                            }
                            {features.includes("MOBILE") && 
                                <Grid item xs={2}>                                
                                    <Card variant="outlined" className={classes.summaryCardAlternate}>
                                        <div className={classes.summaryCardGrid}>
                                            <PhoneIphoneIcon className={classes.icon} />
                                            <div>
                                                <Typography variant="h6" component="div" className={classes.textAlternateLabel}>
                                                    {strings.ticketSummaryMobileApp}
                                                </Typography>
                                                <div className={classes.detailsGrid}>
                                                    <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                        {strings.ticketSummaryTotalOpen}
                                                    </Typography>
                                                    <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                        {summary.ticketsOpenByType.mobileApp!!}
                                                    </Typography>
                                                </div>

                                                <div className={classes.detailsGrid}>
                                                    <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                        {strings.ticketSummaryTotalClosed}
                                                    </Typography>
                                                    <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                        {summary.ticketsClosedByType.mobileApp!!}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Grid>
                            }
                            {features.includes("SIGN") && 
                                <Grid item xs={2}>                                
                                    <Card variant="outlined" className={classes.summaryCardAlternate}>
                                        <div className={classes.summaryCardGrid}>
                                            <SettingsSystemDaydreamIcon className={classes.icon} />
                                            <div>
                                                <Typography variant="h6" component="div" className={classes.textAlternateLabel}>
                                                    {strings.ticketSummaryDigitalSignage}
                                                </Typography>
                                                <div className={classes.detailsGrid}>
                                                    <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                        {strings.ticketSummaryTotalOpen}
                                                    </Typography>
                                                    <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                        {summary.ticketsOpenByType.digitalSignage!!}
                                                    </Typography>
                                                </div>

                                                <div className={classes.detailsGrid}>
                                                    <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                        {strings.ticketSummaryTotalClosed}
                                                    </Typography>
                                                    <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                        {summary.ticketsClosedByType.digitalSignage!!}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>                                
                                </Grid>
                            }
                            <Grid item xs={2}>
                                <Card variant="outlined" className={classes.summaryCardAlternate}>
                                    <div className={classes.summaryCardGrid}>
                                        <ComputerIcon className={classes.icon} />
                                        <div>
                                            <Typography variant="h6" component="div" className={classes.textAlternateLabel}>
                                                {strings.ticketSummaryDigiManager}
                                            </Typography>
                                            <div className={classes.detailsGrid}>
                                                <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                    {strings.ticketSummaryTotalOpen}
                                                </Typography>
                                                <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                    {summary.ticketsOpenByType.digiManager!!}
                                                </Typography>
                                            </div>

                                            <div className={classes.detailsGrid}>
                                                <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                    {strings.ticketSummaryTotalClosed}
                                                </Typography>
                                                <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                    {summary.ticketsClosedByType.digiManager!!}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Grid>  
                            {features.includes("ALERT") && 
                                <Grid item xs={2}>                                
                                    <Card variant="outlined" className={classes.summaryCardAlternate}>
                                        <div className={classes.summaryCardGrid}>
                                            <NotificationImportantIcon className={classes.icon} />
                                            <div>
                                                <Typography variant="h6" component="div" className={classes.textAlternateLabel}>
                                                    {strings.ticketSummaryAlerts}
                                                </Typography>
                                                <div className={classes.detailsGrid}>
                                                    <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                        {strings.ticketSummaryTotalOpen}
                                                    </Typography>
                                                    <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                        {summary.ticketsOpenByType.alerts!!}
                                                    </Typography>
                                                </div>

                                                <div className={classes.detailsGrid}>
                                                    <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                        {strings.ticketSummaryTotalClosed}
                                                    </Typography>
                                                    <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                        {summary.ticketsClosedByType.alerts!!}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>                                
                                </Grid>                                                
                            }
                            <Grid item xs={2}>
                                <Card variant="outlined" className={classes.summaryCardAlternate}>
                                    <div className={classes.summaryCardGrid}>
                                        <AllInclusiveIcon className={classes.icon} />
                                        <div>
                                            <Typography variant="h6" component="div" className={classes.textAlternateLabel}>
                                                {strings.ticketSummaryOther}
                                            </Typography>
                                            <div className={classes.detailsGrid}>
                                                <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                    {strings.ticketSummaryTotalOpen}
                                                </Typography>
                                                <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                    {summary.ticketsOpenByType.other!!}
                                                </Typography>
                                            </div>

                                            <div className={classes.detailsGrid}>
                                                <Typography variant="body1" component="div" className={classes.textAlternateLabel}>
                                                    {strings.ticketSummaryTotalClosed}
                                                </Typography>
                                                <Typography variant="body1" component="div" className={classes.textAlternate}>
                                                    {summary.ticketsClosedByType.other!!}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Grid>  
                        </Grid>
                       
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item>
                                <Grid container spacing={2}>
                                    {ticketsByTypeChartData !== undefined &&
                                        <Grid item>
                                            <Card variant="outlined" className={classes.chartCard}>
                                                <Typography variant="h6" component="div" className={classes.chartBarHeader}>
                                                    {strings.ticketsByType}
                                                </Typography>                                            
                                                <div className={classes.chartByPropertyGrid}>
                                                    <PieChart width={260} height={312} className={classes.chart}>
                                                        <Pie
                                                            dataKey="value"
                                                            data={ticketsByTypeChartData}
                                                            cx="50%"
                                                            cy="50%"
                                                            outerRadius={100}
                                                            fill="#8884d8">                                                            
                                                                {ticketsByTypeChartData.map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={CHART_COLORS[index % CHART_COLORS.length]} />
                                                                ))}
                                                        </Pie>
                                                        <Tooltip content={({ active, payload, label }) => {
                                                            if (active && payload && payload.length) {
                                                                return (
                                                                    <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', fontWeight: 'normal', padding: '10px', width: '100%', height: '100%'}}>
                                                                    <p className="label">{payload[0].payload.name}</p>
                                                                    <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                    </div>
                                                                );
                                                            }

                                                            return null;
                                                        }}/>
                                                    </PieChart>
                                                    <div>
                                                        {ticketsByTypeChartData.map((entry, index) => (
                                                            entry.value > 0 &&
                                                            <div style={{display: 'flex'}}>
                                                                <div style={{height: '15px', width: '15px', marginTop: 'auto', marginBottom: 'auto', marginRight: '10px', backgroundColor: CHART_COLORS[index % CHART_COLORS.length]}}></div>
                                                                <span>{entry.name}</span>
                                                            </div>
                                                            
                                                        ))}
                                                    </div>
                                                </div>                                           
                                            </Card>
                                        </Grid>
                                    }

                                    {ticketsByDayChartData !== undefined &&
                                        <Grid item>
                                            <Card variant="outlined" className={classes.chartCard}>
                                                <Typography variant="h6" component="div" className={classes.chartBarHeader}>
                                                    {strings.ticketsByDay}
                                                </Typography>
                                                <BarChart
                                                    width={480}
                                                    height={312}
                                                    data={ticketsByDayChartData}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="name" />
                                                    <YAxis />
                                                    <Tooltip content={({ active, payload, label }) => {
                                                        if (active && payload && payload.length) {
                                                            if (label === "SU") {
                                                                return (
                                                                    <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                    <p className="label">{"Sunday"}</p>
                                                                    <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                    </div>
                                                                );
                                                            }
                                                            else if (label === "M") {
                                                                return (
                                                                    <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                    <p className="label">{"Monday"}</p>
                                                                    <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                    </div>
                                                                );
                                                            }
                                                            else if (label === "TU") {
                                                                return (
                                                                    <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                    <p className="label">{"Tuesday"}</p>
                                                                    <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                    </div>
                                                                );
                                                            }
                                                            else if (label === "W") {
                                                                return (
                                                                    <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                    <p className="label">{"Wednesday"}</p>
                                                                    <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                    </div>
                                                                );
                                                            }
                                                            else if (label === "TH") {
                                                                return (
                                                                    <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                    <p className="label">{"Thursday"}</p>
                                                                    <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                    </div>
                                                                );
                                                            }
                                                            else if (label === "F") {
                                                                return (
                                                                    <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                    <p className="label">{"Friday"}</p>
                                                                    <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                    </div>
                                                                );
                                                            }
                                                            else if (label === "SA") {
                                                                return (
                                                                    <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                    <p className="label">{"Saturday"}</p>
                                                                    <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                    </div>
                                                                );
                                                            }
                                                        }
                                                        
                                                        return null;
                                                    }}/>
                                                    <Bar dataKey="value">
                                                        {ticketsByDayChartData.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={CHART_COLORS[index % CHART_COLORS.length]} />
                                                        ))}
                                                    </Bar>
                                                </BarChart>
                                            </Card>
                                        </Grid>
                                    }

                                    {ticketsByTimeOfDayChartData !== undefined &&
                                        <Grid item>
                                            <Card variant="outlined" className={classes.chartCard}>
                                                <Typography variant="h6" component="div" className={classes.chartBarHeader}>
                                                    {strings.ticketsByTime}
                                                </Typography>
                                                <BarChart
                                                    width={480}
                                                    height={312}
                                                    data={ticketsByTimeOfDayChartData}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="name" />
                                                    <YAxis />
                                                    <Tooltip content={({ active, payload, label }) => {
                                                        if (active && payload && payload.length) {
                                                            if (label === "12A") {
                                                                return (
                                                                    <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                    <p className="label">{"12AM"}</p>
                                                                    <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                    </div>
                                                                );
                                                            }
                                                            else if (label === "4A") {
                                                                return (
                                                                    <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                    <p className="label">{"4AM"}</p>
                                                                    <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                    </div>
                                                                );
                                                            }
                                                            else if (label === "8A") {
                                                                return (
                                                                    <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                    <p className="label">{"8AM"}</p>
                                                                    <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                    </div>
                                                                );
                                                            }
                                                            else if (label === "12P") {
                                                                return (
                                                                    <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                    <p className="label">{"12PM"}</p>
                                                                    <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                    </div>
                                                                );
                                                            }
                                                            else if (label === "4P") {
                                                                return (
                                                                    <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                    <p className="label">{"4PM"}</p>
                                                                    <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                    </div>
                                                                );
                                                            }
                                                            else if (label === "8P") {
                                                                return (
                                                                    <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                    <p className="label">{"8PM"}</p>
                                                                    <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                    </div>
                                                                );
                                                            }
                                                        }
                                                        
                                                        return null;
                                                    }}/>                                        
                                                    <Bar dataKey="value">
                                                        {ticketsByTimeOfDayChartData.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={CHART_COLORS[index % CHART_COLORS.length]} />
                                                        ))}
                                                    </Bar>
                                                </BarChart>
                                            </Card>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>                        
                            {properties.length > 1 &&
                                <Grid item>
                                    <div className={classes.chartsContainerBottom}>
                                    {ticketsByPropertyChartData !== undefined &&
                                        <Card variant="outlined" className={classes.chartCardLarge}>
                                            <Typography variant="h6" component="div" className={classes.chartBarHeader}>
                                                {strings.ticketsByProperty}
                                            </Typography>
                                            <div className={classes.chartByPropertyGrid}>
                                                <BarChart
                                                    width={520}
                                                    height={304}
                                                    data={ticketsByPropertyChartData}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <YAxis />
                                                    <Tooltip content={({ active, payload, label }) => {
                                                        if (active && payload && payload.length) {
                                                            return (
                                                                <div style={{backgroundColor: '#ffffff', border: '2px solid rgba(0, 0, 0, 0.12)', padding: '10px', width: '100%', height: '100%'}}>
                                                                <p className="label">{payload[0].payload.name}</p>
                                                                <p className="label">{`Tickets : ${payload[0].value}`}</p>
                                                                </div>
                                                            );
                                                        }

                                                        return null;
                                                    }}/>
                                                    <Bar dataKey="value" isAnimationActive={true}>
                                                        {ticketsByPropertyChartData.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={CHART_COLORS[index % CHART_COLORS.length]} />
                                                        ))}
                                                    </Bar>
                                                </BarChart>
                                                <div>
                                                    {ticketsByPropertyChartData.map((entry, index) => (
                                                        <div style={{display: 'flex'}}>
                                                            <div style={{height: '15px', width: '15px', marginTop: 'auto', marginBottom: 'auto', marginRight: '10px', backgroundColor: CHART_COLORS[index % CHART_COLORS.length]}}></div>
                                                            <span>{entry.name}</span>
                                                        </div>
                                                            
                                                    ))}
                                                </div>
                                            </div>
                                        </Card>
                                    }
                                </div>
                                </Grid>
                            }                        
                            </Grid>
                            <Spacer y={2} />
                            <Typography variant="h6">
                                {strings.recentTickets}
                            </Typography>
                            <Spacer y={1} />
                            <DataGrid
                                rows={summary.recentTickets}
                                columns={gridColumns}
                                autoHeight={true} />
                        </div>
                    }
                </div>                 

                {selectedTicket !== undefined &&
                    <TicketNoteDialog 
                        notesDialogOpen={notesDialogOpen}
                        serviceTicket={selectedTicket} 
                        closeDialogCallback={function (): void {
                            setNotesDialogOpen(false);
                        }}                
                    />
                }   
                      
                <NotificationMessage notificationState={[notify, setNotify]} />
            </div>
        </Box>
    );    
};

export default TicketSummary;
