import { AxiosInstance } from "axios";
import { TicketSearchStatus } from "../../../models/dashboard/support/TicketSearchStatus";
import { TicketSearchFilter } from "../../../models/dashboard/support/TicketSearchFilter";
import { ServiceTicket } from "../../../models/dashboard/support/ServiceTicket";
import { TicketSummary } from "../../../models/dashboard/support/TicketSummary";
import { ServiceTicketForCreation } from "../../../models/dashboard/support/ServiceTicketForCreation";

const url = process.env.REACT_APP_MANAGER_API_URL + "/service-ticket/";

export async function listTickets(axiosInstance: AxiosInstance, propertyCodes: string[], startDate: Date, endDate: Date, status: TicketSearchStatus, filter: TicketSearchFilter, searchValue: string, ticketType: string): Promise<ServiceTicket[]> {
    var queryString = `startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&ticketStatus=${status}&searchFilter=${filter}&searchValue=${searchValue}&ticketType=${ticketType}`;    
    return await axiosInstance
        .post(`${url}tickets?${queryString}`, propertyCodes)
        .then(
            (response) =>
                response.data.tickets as ServiceTicket[]
        );
}

export async function getTicket(axiosInstance: AxiosInstance, propertyCode: string, id: string): Promise<ServiceTicket> {
    return await axiosInstance
        .get(`${url}tickets/${id}`)
        .then(
            (response) =>
                response.data.ticket as ServiceTicket
        );
}

export async function getTicketSummary(axiosInstance: AxiosInstance, propertyCodes: string[], startDate: Date, endDate: Date): Promise<TicketSummary> {
    return await axiosInstance
        .post(`${url}tickets/summary?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`, propertyCodes)
        .then(
            (response) =>
                response.data.summary as TicketSummary
        );
}

export async function createTicket(axiosInstance: AxiosInstance, propertyCode: string, ticket: ServiceTicketForCreation) {    
    return await axiosInstance.post(`${url}${propertyCode}/tickets/`, ticket).then((response) => {
        return;
    });
}

export async function uploadFile(
    axiosInstance: AxiosInstance,
    ticketId: string,
    fileName: string,
    fileData: File,    
    propertyId: string | undefined
) {   
    const formData = new FormData();
    formData.append("TicketId", ticketId);

    if (propertyId !== undefined) {
        formData.append("PropertyId", propertyId!!);
    }        

    formData.append(fileName, fileData);

    const config = {
        headers: {
            Accept: "application/json",
            "content-type": "multipart/form-data",
        },
    };

    return await axiosInstance.put(url, formData, config); //axiosInstance.put(`${url}${propertyId}/tickets/${ticketId}/documents`, formData, config);
}