export enum TicketType {
    Other = 0,
    Network = 1,
    Voice = 2,
    Security = 3,
    DigitalSignage = 4,
    MobileApp = 5,
    ITV = 6,
    Audio = 7,
    Television = 8,
    DigiManager = 9,
    Casting = 10,
    Alerts = 11,
    BackOfHouse = 12
}